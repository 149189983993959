import axios from 'axios'
const headers = {
  'Content-Type': 'application/json'
}
export const ApiContent = axios.create({
  baseURL: 'https://sitesotreq.manacadigital.com.br/',
 // baseURL: 'http://192.168.15.110:3000/',
  // baseURL: 'http://64.225.8.81:8070/',
  headers

})

export default ApiContent
