import axios from 'axios'
const headers = {
  'Content-Type': 'application/json'
}

export const ApiContato = axios.create({
 //  baseURL: 'http://192.168.15.18:3000/'
 baseURL: 'https://sitesotreq.manacadigital.com.br/',
// baseURL: 'http://64.225.8.81:8018/',
})

export default ApiContato
