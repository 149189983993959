<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="popModal"
    tabindex="-1"
    aria-labelledby="popModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered border-0">
      <div class="modal-content border-0">
        <div class="modal-header border-0">
          <button
            type="button"
            class="close popbut"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="d-none d-lg-block">
            <a class="closepop" href="https://hub.sotreq.com.br/aguarde-a-black-friday-sotreq-2024-geral" target="_blank">
              <img
                class="img-fluid"
          src="images/desk.jpg"
              />
            </a>
          </div>
          <div class="d-none d-sm-block d-md-block d-lg-none">
            <a class="closepop" href="https://hub.sotreq.com.br/aguarde-a-black-friday-sotreq-2024-geral" target="_blank">
              <img
                class="img-fluid p-5"
                src="images/mobile.jpg"
              />
            </a>
          </div>
        </div>
        <div class="modal-footer border-0"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePopup",
  mounted() {
    this.openPop();
    this.closePop();
  },
  methods: {
    openPop() {
      $("#popModal").modal("show");
    },
    closePop() {
      $(".closepop").click(function () {
        console.log("clicou");
        $("#popModal").modal("hide");
      });
    },
  },
};
</script>
<style scoped>

.modal-content {
  background-color: transparent !important;
}
.popbut {
  background-color: transparent !important;
  color: white !important;
}
.popbut:hover {
  color: #fafafa !important;
}

.d-none {
    display: none!important
}

.d-inline {
    display: inline!important
}

.d-inline-block {
    display: inline-block!important
}

.d-block {
    display: block!important
}

.d-table {
    display: table!important
}

.d-table-row {
    display: table-row!important
}

.d-table-cell {
    display: table-cell!important
}

.d-flex {
    display: -ms-flexbox!important;
    display: flex!important
}

.d-inline-flex {
    display: -ms-inline-flexbox!important;
    display: inline-flex!important
}
@media(max-width: 578px) {

      button.close.popbut {
    margin-top: 150px;
}
}
@media (min-width: 576px) {
    .d-sm-none {
        display:none!important
    }

    .d-sm-inline {
        display: inline!important
    }

    .d-sm-inline-block {
        display: inline-block!important
    }

    .d-sm-block {
        display: block!important
    }

    .d-sm-table {
        display: table!important
    }

    .d-sm-table-row {
        display: table-row!important
    }

    .d-sm-table-cell {
        display: table-cell!important
    }

    .d-sm-flex {
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-sm-inline-flex {
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }
    .modal-body {
      height: unset !important;
    }
  }

@media (min-width: 768px) {
    .d-md-none {
        display:none!important
    }

    .d-md-inline {
        display: inline!important
    }

    .d-md-inline-block {
        display: inline-block!important
    }

    .d-md-block {
        display: block!important
    }

    .d-md-table {
        display: table!important
    }

    .d-md-table-row {
        display: table-row!important
    }

    .d-md-table-cell {
        display: table-cell!important
    }

    .d-md-flex {
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-md-inline-flex {
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display:none!important
    }

    .d-lg-inline {
        display: inline!important
    }

    .d-lg-inline-block {
        display: inline-block!important
    }

    .d-lg-block {
        display: block!important
    }

    .d-lg-table {
        display: table!important
    }

    .d-lg-table-row {
        display: table-row!important
    }

    .d-lg-table-cell {
        display: table-cell!important
    }

    .d-lg-flex {
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-lg-inline-flex {
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display:none!important
    }

    .d-xl-inline {
        display: inline!important
    }

    .d-xl-inline-block {
        display: inline-block!important
    }

    .d-xl-block {
        display: block!important
    }

    .d-xl-table {
        display: table!important
    }

    .d-xl-table-row {
        display: table-row!important
    }

    .d-xl-table-cell {
        display: table-cell!important
    }

    .d-xl-flex {
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-xl-inline-flex {
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }
}
@media(max-width: 570px) {
  .d-sm-block {
    display: block !important;
  }
}
</style>

